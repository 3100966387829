import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import BookImage from './BookImage';
import DownloadIcon from '../../../../assets/images/success/download_icon.svg';

interface ThankYouSectionProps {
  title: string;
  subtitle: string;
  img: string;
  alt: string;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4.5rem 0;
  width: 100%;
  gap: 110px;
  height: 452px;
  padding: 0 1rem;
  @media ${tablet} {
    margin: 0;
    padding: 3rem 1rem;
    flex-direction: column;
    height: auto;
    gap: 3rem;
  }
`;

const TYTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  gap: 3rem;
  @media ${tablet} {
    align-items: center;
    gap: 2rem;
    width: 100%;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  @media ${tablet} {
    gap: 1.5rem;
    align-items: center;
  }
`;

const TYTitle = styled(Text)`
  width: 100%;
  max-width: 487px;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 48px;
  text-align: left;
  strong {
    font-weight: 800;
    color: ${({ theme }) => theme.colors.primary};
  }
  @media ${tablet} {
    text-align: center;
    font-size: 1.5rem;
    max-width: 303px;
    line-height: 31.2px;
  }
`;

const TYText = styled(Text)`
  width: 100%;
  max-width: 418px;
  text-align: left;
  strong {
    font-weight: 600;
  }
  @media ${tablet} {
    text-align: center;
    max-width: 303px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 18px;
`;

const DecoratePlusSign = styled(Text).attrs({
  color: 'dark100',
})`
  font-size: 35px;
`;

const DownloadIconImg = styled(DownloadIcon)`
  width: 1.5rem;
  height: 1.5rem;
  & path {
    stroke: #fff;
  }
`;

const DownloadButton = styled.a`
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 1.4rem */
  letter-spacing: -0.024rem;
  color: #fff !important;
  border-radius: 6.25rem;
  background: #804da1;
  padding: 1rem 2.125rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.81rem;
`;

const ThankYouSection: FC<ThankYouSectionProps> = ({
  img,
  subtitle,
  title,
  alt,
}) => {
  const { isTablet } = useQuery();

  const { user } = useSelector((state: AppState) => state);
  const dogBreedList = useSelector((state: AppState) => state?.dogBreed?.list);
  const { coverUrl, textColor, userName, userDogName, ebookUrl, dogs_breed } =
    user?.quiz_answers;
  const selectedPlans = user?.selected_plans;

  const buildValueKey = (item: string) =>
    item.toLowerCase().replace(/\s+/g, '_');

  const ebookCheck = selectedPlans
    ?.filter(item => !item.is_subscription)
    .some(item => item.type === 'digital');
  const paperBook = selectedPlans
    ?.filter(item => !item.is_subscription)
    .some(item => item.type === 'physical');

  const resolvePageTextContent = () => {
    if (ebookCheck && !paperBook) {
      return {
        title: 'Your e-book is being prepared 🎉!',
        subtitlePlaceHolder: 'It will be delivered to your email.',
        subtitle: 'It will be delivered to your email.',
        downloadButton: 'Download my e-book',
      };
    }
    if (!ebookCheck && paperBook) {
      return {
        title: 'Your physical book is being prepared 🎉! ',
        subtitle: 'It should arrive in 8-16 business days',
      };
    }
    return {
      title: 'Your physical & e-book are being prepared 🎉!',
      subtitle:
        'Your physical book should arrive in 8-16 business days and e-book will be sent to your email',
      downloadButton: 'Download my e-book',
    };
  };

  const minimizeBookTitle =
    (userName?.length || 0 + userDogName?.length || 0) > 15;

  return (
    <Container>
      {ebookCheck && !paperBook && (
        <BookImage
          isBackground
          coverUrl={coverUrl}
          alt={alt}
          textColor={textColor}
          userName={userName}
          userDogName={userDogName}
          isEBook
          width={isTablet ? '170px' : '200px'}
          top={
            isTablet
              ? minimizeBookTitle
                ? '19px'
                : '15px'
              : minimizeBookTitle
              ? '22px'
              : '18px'
          }
          fontSize={
            isTablet
              ? minimizeBookTitle
                ? '6px'
                : '14px'
              : minimizeBookTitle
              ? '7px'
              : '14px'
          }
        />
      )}
      {!ebookCheck && paperBook && (
        <BookImage
          isBackground
          coverUrl={coverUrl}
          alt={alt}
          width={isTablet ? '170px' : '200px'}
          textColor={textColor}
          userName={userName}
          userDogName={userDogName}
          top={
            isTablet
              ? minimizeBookTitle
                ? '19px'
                : '15px'
              : minimizeBookTitle
              ? '22px'
              : '18px'
          }
          fontSize={
            isTablet
              ? minimizeBookTitle
                ? '6px'
                : '14px'
              : minimizeBookTitle
              ? '7px'
              : '14px'
          }
        />
      )}
      {ebookCheck && paperBook && (
        <ImageContainer>
          <BookImage
            coverUrl={coverUrl}
            alt={alt}
            textColor={textColor}
            userName={userName}
            userDogName={userDogName}
            width={isTablet ? '110px' : '150px'}
            top={isTablet ? '11px' : '15px'}
            fontSize={
              isTablet
                ? minimizeBookTitle
                  ? '4px'
                  : '6px'
                : minimizeBookTitle
                ? '5.5px'
                : '8px'
            }
          />
          <DecoratePlusSign>+</DecoratePlusSign>
          <BookImage
            coverUrl={coverUrl}
            alt={alt}
            textColor={textColor}
            userName={userName}
            userDogName={userDogName}
            width={isTablet ? '110px' : '150px'}
            top={isTablet ? '11px' : '15px'}
            fontSize={
              isTablet
                ? minimizeBookTitle
                  ? '4px'
                  : '6px'
                : minimizeBookTitle
                ? '5.5px'
                : '8px'
            }
            isEBook
          />
        </ImageContainer>
      )}

      <TYTextContainer>
        <TextContainer>
          <TYTitle
            color="dark100"
            dangerouslySetInnerHTML={{ __html: resolvePageTextContent().title }}
          />
          <TYText
            type={isTablet ? 'body' : 'body'}
            color="dark100"
            dangerouslySetInnerHTML={{
              __html: resolvePageTextContent().subtitle,
            }}
          />
          {dogBreedList.find(
            item => item.value === buildValueKey(dogs_breed).trim(),
          )?.contentReady ? (
            resolvePageTextContent()?.downloadButton && (
              <DownloadButton href={ebookUrl} target="_blank">
                <DownloadIconImg />
                {resolvePageTextContent()?.downloadButton}
              </DownloadButton>
            )
          ) : resolvePageTextContent()?.subtitlePlaceHolder ? (
            <TYText
              type={isTablet ? 'body' : 'body'}
              color="dark100"
              dangerouslySetInnerHTML={{
                __html: resolvePageTextContent().subtitlePlaceHolder,
              }}
            />
          ) : null}
        </TextContainer>
      </TYTextContainer>
    </Container>
  );
};

export default ThankYouSection;
