import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import AppleStoreButton from './AppleStoreButton';
import GooglePlayButton from './GooglePlayButton';
import PlatformSelector from './PlatformSelector';
import { tablet, useQuery } from 'styles/breakpoints';
import Carousel from './Carousel';

export type PlatformType = 'ios' | 'android';

interface HowToSectionProps {
  title: string;
  subtitle?: string;
  ios: {
    tabTitle: string;
    steps: { text: string; image: string }[];
  };
  android: {
    tabTitle: string;
    steps: { text: string; image: string }[];
  };
  bottomText?: string;
}

const Container = styled.div`
  width: 100%;
  background-color: #fff9ef;
  display: flex;
  justify-content: center;
  padding: 4.5rem 1rem;
  @media ${tablet} {
    padding: 3rem 1rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 71.25rem;
  width: 100%;
  gap: 5rem;
  @media ${tablet} {
    gap: 3rem;
  }
`;
const StoresContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;
const StoresAndTabsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  @media ${tablet} {
    gap: 2rem;
  }
`;

const Heading = styled(Text)`
  max-width: 700px;
  text-align: center;
  @media ${tablet} {
    text-align: center;
  }
`;

const MockupsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;

const MockupBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Circle = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryDark};
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

const MockupText = styled(Text)``;

const Subtitle = styled(Text)`
  strong {
    font-weight: 600;
  }
  @media ${tablet} {
    text-align: center;
  }
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.dark40};
  height: 1px;
  width: 100%;
`;
const BadgesWrapper = styled.div`
  display: flex;
  flex-direction: row:
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 0 1rem;
  @media ${tablet} {
    gap: 1rem;
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  height: 20rem;
  // margin-bottom: 1.5rem;
`;

const BelowText = styled(Text)`
  @media ${tablet} {
    text-align: center;
  }
`;

const HowToSection: FC<HowToSectionProps> = ({
  android,
  ios,
  subtitle,
  bottomText,
  title,
}) => {
  const [platform, setPlatform] = useState<PlatformType>('ios');
  const [slide, setSlide] = useState<number>(0);

  const steps = platform === 'android' ? android?.steps : ios?.steps;

  const { isTablet } = useQuery();

  useEffect(() => {
    setSlide(0);
  }, [isTablet, platform]);

  const renderMockups = () =>
    steps?.map((mockup, index) => (
      <MockupBlock key={index}>
        <StyledDynamicImage src={mockup.image} alt={mockup.text} />
        <TextContainer>
          <Circle>
            <Text type="bodyS700" color="light0">
              {index + 1}
            </Text>
          </Circle>
          <MockupText type="body" color="dark100">
            {mockup?.text}
          </MockupText>
        </TextContainer>
      </MockupBlock>
    ));

  return (
    <Container>
      <ContentContainer>
        <StoresAndTabsContainer>
          <Heading type={isTablet ? 'h2600' : 'h125Rem'} color="dark100">
            {title}
          </Heading>
          <StoresContainer>
            {subtitle && (
              <Subtitle
                type="body"
                color="dark100"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}

            <BadgesWrapper>
              <AppleStoreButton />
              <GooglePlayButton />
            </BadgesWrapper>
          </StoresContainer>
        </StoresAndTabsContainer>
        <PlatformSelector
          iosTabTitle={ios?.tabTitle}
          androidTabTitle={android?.tabTitle}
          platform={platform}
          onSelect={setPlatform}
        />

        {isTablet ? (
          <Carousel
            slide={slide}
            onSlide={setSlide}
            slideComponents={renderMockups()}
          />
        ) : (
          <MockupsContainer>{renderMockups()}</MockupsContainer>
        )}

        <BelowText type={isTablet ? 'body' : 'h3400'} color="dark100">
          {bottomText}
        </BelowText>
      </ContentContainer>
    </Container>
  );
};

export default HowToSection;
